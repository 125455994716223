import Flickity from "flickity"
import 'flickity/dist/flickity.min.css'

class Slideshow {

  constructor(elem) {
    this.element = elem

    const cells = this.element.querySelectorAll(".carousel-cell")
    if (cells.length > 1) {
      this.init()
    }
  }

  init() {
    this.flickity = new Flickity(this.element, {
      cellSelector: ".carousel-cell",
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyLoad: 1
    })

    this.addControls()
  }

  addControls() {
    this.controlPrev = document.createElement("div")
    this.controlPrev.classList.add("carousel-control", "carousel-control--prev")
    this.controlPrev.dataset.direction = "prev"
    this.controlPrev.innerHTML = "&lt;&lt;"
    this.element.appendChild(this.controlPrev)

    this.controlNext = document.createElement("div")
    this.controlNext.classList.add("carousel-control", "carousel-control--next")
    this.controlNext.dataset.direction = "next"
    this.controlNext.innerHTML = "&gt;&gt;"
    this.element.appendChild(this.controlNext)

    this.controls = [this.controlPrev, this.controlNext]

    this.controls.forEach(control => {
      control.addEventListener("click", (event) => {
        const direction = control.dataset.direction
        direction == "next" ? this.flickity.next() : this.flickity.previous()
      })
    })
  }
}

export { Slideshow as default };
