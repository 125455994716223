// import Sticky from './js/sticky';
import Expandable from './js/expandable';
import { NavItem } from './js/scroll';
import Slideshow from "./js/slideshow";

import StickyFill from 'stickyfilljs';

import './css/styles.scss';

window.addEventListener('DOMContentLoaded', () => {

  // navigation
  const nav = document.getElementById('navigation__sections'),
        navItems = nav.querySelectorAll('.navigation__sections a');

  navItems.forEach((elem) => {
    new NavItem(elem, nav, true);
  });

  // const residentsNav = document.querySelector('.residents__navigation'),
  //       residentsNavItems = residentsNav.querySelectorAll('a');

  // residentsNavItems.forEach((elem) => {
  //   new NavItem(elem, residentsNav, false);
  // });

  const stickies = document.querySelectorAll('sticky');
  StickyFill.add(stickies);

  // init swipers

  // initSwipers(swipers);

  // slideshows
  const slideshows = document.querySelectorAll('.main-carousel');
  slideshows.forEach((elem, index) => {
    let slideshow = new Slideshow(elem);
  });

  // expandable elements
  const expandables = document.querySelectorAll('.js-expandable');

  expandables.forEach((elem) => {
    let expandable = new Expandable(elem);
  });


});
