import 'waypoints/lib/noframework.waypoints.js';
import jump from 'jump.js';

class NavItem {

  constructor(elem, nav, updates = false) {
    console.log('init NavItem', elem, nav, updates);

    this.link = elem,
    this.target = document.querySelector(this.link.getAttribute('href')),
    this.nav = nav
    this.updates = updates;

    if (updates) {
      console.log('init waypoint', this.target);

      this.waypoint = new Waypoint({
        element: this.target,
        handler: (direction) => {
          this.update()
        }, offset: -1
      });
    }

    this.link.addEventListener('click', (e) => {
      e.preventDefault();
      this.jump();
    });
  }

  update() {
    console.log('update', this.link, this.nav);

    this.nav.querySelectorAll('a').forEach((elem) => {
      elem.classList.remove('link--active');
    });

    this.link.classList.add('link--active');
  }

  jump() {
    console.log('jump to', this.target);

    let offset = -this.nav.offsetHeight;
    jump(this.target, {
      offset: offset,
      callback: () => {
        if (this.updates) {
          this.update();
        }
      }
    });
  }
}

export { NavItem }
