class Expandable {

  constructor(elem, btn = 'js-expandable__btn') {
    this.elem = elem;
    this.expandableClass = 'js-expandable--expanded';
    this.contractableClass = 'js-expandable--contracted';

    this.contract()

    this.elem.addEventListener('click', (e) => {
      if (e.target.classList.contains(btn)) {
        this.toggle()
      }
    });
  }

  toggle() {
    if (this.elem.classList.contains(this.expandableClass)) {
      console.log('contract')
      this.contract()
    } else {
      console.log('expand')
      this.expand()
    }

    window.dispatchEvent(new Event('resize'))
  }

  expand() {
    if (!this.elem.classList.contains(this.expandableClass)) {
      this.elem.classList.add(this.expandableClass)
      this.elem.classList.remove(this.contractableClass)
    }
  }

  contract() {
    if (!this.elem.classList.contains(this.contractableClass)) {
      this.elem.classList.add(this.contractableClass)
      this.elem.classList.remove(this.expandableClass)
    }
  }
}

export { Expandable as default };
